export const states = [ 
    {value: "Andhra Pradesh"},
    {value: "Arunachal Pradesh"},
    {value: "assam"},
    {value: "bihar"},
    {value: "Chhattisgarh"},
    {value: "Goa"},
    {value: "gujarat"},
    {value: "Haryana"},
    {value: "Himachal Pradesh"},
    {value: "Jammu and Kashmir"},
    {value: "Jharkhand"},
    {value: "karnataka"},
    {value: "kerala"},
    {value: "Madhya Pradesh"},
    {value: "maharashtra"},
    {value: "Manipur"},
    {value: "Meghalaya"},
    {value: "Mizoram"},
    {value: "nagaland"},
    {value: "Odisha"},
    {value: "punjab"},
    {value: "rajasthan"},
    {value: "Sikkim"},
    {value: "Tamil Nadu"},
    {value: "telangana"},
    {value: "Tripura"},
    {value: "Uttarakhand"},
    {value: "Uttar Pradesh"},
    {value: "west Bengal"},
    {value: "chandigarh"},
    {value: "Delhi"}
]