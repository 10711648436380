const Dashboard = () => {
    return (
        <div>
        <iframe 
        width="100%"
        height="940px"
        style={{marginTop: '10px'}}
        title="Twitter Data"
        src="https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/0?id=DASHBOARD_601b9e214c7a6b689d76f493" />
        <center>All this data is crowd-sourced and we do not own this dashboard. Please contact for credits</center>
        </div>
    )
}

export default Dashboard